
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl",
  "de",
  "fr",
  "es"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46ts_ed48fe66",
      load: () => import("#nuxt-i18n/ed48fe66" /* webpackChunkName: "locale_en_46ts_ed48fe66" */),
      cache: true
    }
  ],
  nl: [
    {
      key: "locale_nl_46ts_d4d1a6d7",
      load: () => import("#nuxt-i18n/d4d1a6d7" /* webpackChunkName: "locale_nl_46ts_d4d1a6d7" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_46ts_5e342c21",
      load: () => import("#nuxt-i18n/5e342c21" /* webpackChunkName: "locale_de_46ts_5e342c21" */),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_46ts_490e40e0",
      load: () => import("#nuxt-i18n/490e40e0" /* webpackChunkName: "locale_fr_46ts_490e40e0" */),
      cache: true
    }
  ],
  es: [
    {
      key: "locale_es_46ts_e844cfbf",
      load: () => import("#nuxt-i18n/e844cfbf" /* webpackChunkName: "locale_es_46ts_e844cfbf" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/887749ea" /* webpackChunkName: "config_vue_45i18n_46ts_887749ea" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      files: [
        "/vercel/path0/i18n/language/en.ts"
      ]
    },
    {
      code: "nl",
      files: [
        "/vercel/path0/i18n/language/nl.ts"
      ]
    },
    {
      code: "de",
      files: [
        "/vercel/path0/i18n/language/de.ts"
      ]
    },
    {
      code: "fr",
      files: [
        "/vercel/path0/i18n/language/fr.ts"
      ]
    },
    {
      code: "es",
      files: [
        "/vercel/path0/i18n/language/es.ts"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "language/",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "config",
  pages: {
    contact: {
      en: "/contact",
      de: "/kontakt",
      nl: "/contact",
      fr: "/contact",
      es: "/contacto"
    },
    "contact-success": {
      en: "/contact-success",
      de: "/contact-success",
      nl: "/contact-success",
      fr: "/contact-success",
      es: "/contact-success"
    },
    "events/index": {
      en: "/events",
      de: "/veranstaltungen",
      nl: "/evenementen",
      fr: "/evenements",
      es: "/eventos"
    },
    "events/[item]": {
      en: "/events/[item]",
      de: "/veranstaltungen/[item]",
      nl: "/evenementen/[item]",
      fr: "/evenements/[item]",
      es: "/eventos/[item]"
    },
    faq: {
      en: "/faq",
      de: "/haufig-gestellte-fragen",
      nl: "/veelgestelde-vragen",
      fr: "/questions-frequemment-posees",
      es: "/preguntas-frecuentes"
    },
    gallery: {
      en: "/gallery",
      de: "/galerie",
      nl: "/galerij",
      fr: "/galerie",
      es: "/galeria"
    },
    highlights: {
      en: "/location",
      de: "/standort",
      nl: "/locatie",
      fr: "/emplacement",
      es: "/ubicacion"
    },
    index: {
      en: "/",
      de: "/",
      nl: "/",
      fr: "/",
      es: "/"
    },
    meetingform: {
      en: "/meeting-form",
      de: "/besprechungsformular",
      nl: "/vergaderformulier",
      fr: "/formulaire-de-reunion",
      es: "/forma-de-reunion"
    },
    "meetingform-success": {
      en: "/meeting-success",
      de: "/meeting-success",
      nl: "/meeting-success",
      fr: "/meeting-success",
      es: "/meeting-success"
    },
    "meetingpackages/index": {
      en: "/meeting-packages",
      de: "/tagungspakete",
      nl: "/vergaderarrangementen",
      fr: "/paquets-de-reunions",
      es: "/paquetes-de-reunion"
    },
    "meetingpackages/[item]": {
      en: "/meeting-packages/[item]",
      de: "/tagungspakete/[item]",
      nl: "/vergaderarrangementen/[item]",
      fr: "/paquets-de-reunions/[item]",
      es: "/paquetes-de-reunion/[item]"
    },
    meetingrooms: {
      en: "/meeting-rooms",
      de: "/tagungsraume",
      nl: "/vergaderzalen",
      fr: "/salles-de-reunion",
      es: "/salas-de-reuniones"
    },
    "news/index": {
      en: "/news",
      de: "/nachrichten",
      nl: "/nieuws",
      fr: "/actualites",
      es: "/noticias"
    },
    "news/[item]": {
      en: "/news/[item]",
      de: "/nachrichten/[item]",
      nl: "/nieuws/[item]",
      fr: "/actualites/[item]",
      es: "/noticias/[item]"
    },
    "packages/index": {
      en: "/special-offers",
      de: "/arrangements",
      nl: "/arrangementen",
      fr: "/arrangements",
      es: "/arreglos"
    },
    "packages/[item]": {
      en: "/special-offers/[item]",
      de: "/arrangements/[item]",
      nl: "/arrangementen/[item]",
      fr: "/arrangements/[item]",
      es: "/arreglos/[item]"
    },
    reviews: {
      en: "/reviews",
      de: "/rezensionen",
      nl: "/beoordelingen",
      fr: "/revues",
      es: "/resenas"
    },
    rooms: {
      en: "/rooms",
      de: "/zimmer",
      nl: "/kamers",
      fr: "/chambres",
      es: "/habitaciones"
    },
    sitemap: {
      en: "/sitemap",
      de: "/sitemap",
      nl: "/sitemap",
      fr: "/sitemap",
      es: "/sitemap"
    }
  },
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    files: [
      {
        path: "/vercel/path0/i18n/language/en.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "nl",
    files: [
      {
        path: "/vercel/path0/i18n/language/nl.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    files: [
      {
        path: "/vercel/path0/i18n/language/de.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    files: [
      {
        path: "/vercel/path0/i18n/language/fr.ts",
        cache: undefined
      }
    ]
  },
  {
    code: "es",
    files: [
      {
        path: "/vercel/path0/i18n/language/es.ts",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/